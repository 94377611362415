
import { Component, Vue } from 'vue-property-decorator';
import MultipleImageUpload from '@/component/multiple-image-upload.vue';
import { changeLoading } from '@/util/decorators';
const dateFormat = require('bmh/date-format');

import {
  confirmRefund,
  getRefundList,
  RefundData,
  initRefundData,
  getWarehouseList,
  initWarehouseData,
  WarehouseData,
  QueryParams,
  exportData,
} from '@/api/order/refund';
import { PaginationData } from '@/api/operation/customer';
import { Moment } from 'moment';
import moment from 'moment';
import RefundDetil from './RefundDetil.vue';
const UPLOAD_DIR = {
  video: 'ware/video',
  carousel: 'ware/carousel',
  detail: 'ware/detail',
};
const SEARCH_TYPE = [
  {
    id: 0,
    value: '售后ID',
  },
  {
    id: 1,
    value: '订单ID',
  },
  {
    id: 2,
    value: '收货人姓名',
  },
  {
    id: 3,
    value: '收货人手机号',
  },
  {
    id: 4,
    value: '收货人地址',
  },
];
const REASON_FOR_THE_CREDIT = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '商品质量有问题',
  },
  {
    id: 2,
    value: '漏发/错发',
  },
  {
    id: 3,
    value: '不想要了',
  },
  {
    id: 4,
    value: '其他原因',
  },
];
const REFUND_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '已申请退款',
  },
  {
    id: 2,
    value: '退款成功',
  },
  {
    id: 3,
    value: '拒绝退款',
  },
];
interface Form {
  search_value: string;
  goods_title: string;
  warehouse_id: number;
  create_time: Moment[];
  status: number;
  is_download: number;
  search_type: number;
  reason_type: number;
  refund_type: number;
  check_time: Moment[];
}
function getFormData() {
  return {
    search_value: '',
    goods_title: '',
    warehouse_id: 0,
    create_time: [],
    status: 0,
    is_download: 0,
    search_type: 0,
    reason_type: 0,
    refund_type: 0,
    check_time: [],
  };
}
@Component({
  name: 'OrderAfterSale',
  components: {
    MultipleImageUpload,
    RefundDetil
  },
})
export default class OrderAfterSale extends Vue {
  moment: any = moment;
  show_fail_refund_modal: boolean = false; // 退款失败弹框
  preview_image: string = ''; // 预览图片的路径
  preview_visible: boolean = false; // 预览图片的模态框显示状态
  refuse_modal: boolean = false;
  show_refund_modal: boolean = false;
  form: Form = getFormData();
  is_loading: boolean = false;
  list: any = [];
  refuse_refund_reason: string = '';
  img_url_list: any = [];
  MAX_REFUSE_IMG_LENGTH: number = 9;
  refund_msg: any = [];
  show_img: boolean = false; //问题描述图片查看
  refund_data: PaginationData<RefundData> = initRefundData();
  warehouse_list: WarehouseData[] = initWarehouseData();
  rowsKeys: number[] = [];
  columns: any = [
    // {
    //   title:'',
    //   width:50,
    // },
    {
      title: '商品信息',
      dataIndex: 'goods',
      scopedSlots: { customRender: 'goods' },
      width: 300,
    },
    {
      title: '买家/收货人',
      dataIndex: 'buyer',
      scopedSlots: { customRender: 'buyer' },
      width: 200,
    },
    {
      title: '发货门店',
      dataIndex: 'goods.warehouse_name',
      scopedSlots: { customRender: 'warehouse' },
      width: 150,
      align: 'center',
    },
    {
      title: '退款数量',
      dataIndex: 'goods_cost',
      scopedSlots: { customRender: 'goods_cost' },
      width: 150,
      align: 'center',
    },
    {
      title: '退款金额',
      dataIndex: 'coupon_deduction_amount',
      scopedSlots: { customRender: 'coupon_deduction_amount' },
      width: 150,
      align: 'center',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '退款原因',
      dataIndex: 'total_amount_paid',
      scopedSlots: { customRender: 'total_amount_paid' },
      width: 150,
      align: 'center',
    },
    {
      title: '问题描述',
      dataIndex: 'remark',
      scopedSlots: { customRender: 'remark' },
      width: 200,
      align: 'center',
    },
    {
      title: '退款状态',
      dataIndex: 'pay_type',
      scopedSlots: { customRender: 'pay_type' },
      width: 200,
      align: 'center',
    },
    {
      title: '操作时间',
      dataIndex: 'check_time',
      scopedSlots: { customRender: 'check_time' },
      width: 200,
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'knight_order',
      scopedSlots: { customRender: 'knight_order' },
      width: 150,
      fixed: 'right',
      align: 'center',
    },
  ].map(item => {
    //设置列默认属性
    return {
      ...item,
      customHeaderCell: (column: any) => {
        return {
          style: {
            'text-align': 'center',
          },
        };
      },
    };
  });
  get searchType() {
    return SEARCH_TYPE;
  }
  get UPLOAD_DIR(): any {
    return UPLOAD_DIR;
  }
  // 退款原因
  get reasonForTheCredit() {
    return REASON_FOR_THE_CREDIT;
  }
  // 售后订单状态
  get refundStatus() {
    return REFUND_STATUS;
  }
  expandIcon(props: any) {
    const h = this.$createElement;
    if (props.record.level == 1) {
      return undefined;
    }
    if (props.expanded) {
      return h(
        'a',
        {
          style: {
            margin: '0 40px 0 10px',
          },
          on: {
            click: (e: any) => {
              props.onExpand(props.record, e);
            },
          },
        },
        [
          h('a-icon', {
            props: {
              type: 'down',
            },
          }),
        ]
      );
    } else {
      return h(
        'a',
        {
          style: {
            margin: '0 40px 0 10px',
          },
          on: {
            click: (e: any) => {
              props.onExpand(props.record, e);
            },
          },
        },
        [
          h('a-icon', {
            props: {
              type: 'right',
            },
          }),
        ]
      );
    }
  }
  dateFormat(time: any) {
    return dateFormat(new Date(time * 1000), '%Y-%m-%d %H:%M:%S');
  }
  handleRows(expand: any, data: any) {
    if (expand) {
      this.rowsKeys.push(data.id);
    } else {
      const index = this.rowsKeys.indexOf(data.id);
      index !== -1 ? this.rowsKeys.splice(index, 1) : null;
    }
  }
  //分页
  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  //切换分页
  changePage(data: any, filters, sorter) {
    // this.searchBtn(data.current);
    let sort: any = sorter.order; // 排序类型，正序/倒序/不排序
    if (sort == 'ascend') {
      sort = 'amount_asc';
    } else if (sort == 'descend') {
      sort = 'amount_desc';
    } else {
      sort = undefined;
    }
    this.sortList(data.current, sort);
  }
  //查看图片
  check_img(record: any) {
    this.refund_msg = record;
    this.refund_msg.reason_img_url = this.refund_msg.reason_image_url.split(',');
    this.show_img = true;
  }
  //拒绝退款
  refuse(record: any) {
    this.img_url_list = [];
    this.refund_msg = record;
    this.refuse_refund_reason = '';
    this.refuse_modal = true;
  }
  beforeCommonUpload(file: any) {
    if (file.size / 1024 / 1024 > 2) {
      this.$message.error('上传图片大小不能超过2M');
      return false;
    }
  }
  //确认拒绝退款
  async confirm_refuse() {
    if (!this.refuse_refund_reason) {
      this.$message.error('请输入拒绝退款原因');
      return;
    }
    const send_data = {
      type: 'disagree',
      reason_image_url: this.img_url_list.length === 0 ? '' : this.img_url_list.join(','),
      reason: this.refuse_refund_reason,
    };
    const data_id = this.refund_msg.id.split('-');

    const res: any = await confirmRefund(data_id[1], send_data);
    if (res.status !== 200) {
      this.$message.error('拒绝退款失败');
      return;
    }
    this.img_url_list = [];
    this.$message.success('拒绝退款成功');
    this.refuse_modal = false;
    this.searchBtn(this.refund_data.current);
  }
  //展示退款失败
  show_refund_fail(record: any) {
    this.refund_msg = record;
    if (record.status === 3) {
      // this.refund_msg.reason_img_url = this.refund_msg.disagree_reason_image
      //   ? this.refund_msg.disagree_reason_image.split(',')
      //   : [];
      this.refund_msg.disagree_reason_image = this.refund_msg.disagree_reason_image
        ? this.refund_msg.disagree_reason_image.split(',')
        : [];
      this.show_fail_refund_modal = true;
    }
  }
  //退款
  agreeRefund(record: any) {
    this.refund_msg = record;
    this.show_refund_modal = true;
  }
  //确认退款
  async confirm_refund() {
    const data_id = this.refund_msg.id.split('-');
    const res: any = await confirmRefund(data_id[1], { type: 'agree' });
    if (res.status !== 200) {
      this.$message.error('退款失败');
      return;
    }
    this.$message.success('退款成功');
    this.show_refund_modal = false;
    this.searchBtn(this.refund_data.current);
  }
  clearForm() {
    this.form = getFormData();
    this.searchBtn(1);
  }
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
    return true
  }
  // 关闭模态窗
  handlePreviewCancel() {
    this.preview_visible = false;
  }
  @changeLoading(['is_loading'])
  async searchBtn(pages: number = 1) {
    const start_time = this.form.create_time[0]
      ? Math.floor(+this.form.create_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.create_time[1]
      ? Math.floor(+this.form.create_time[1].format('x') / 1000)
      : '';
    const check_start_time = this.form.check_time[0]
      ? Math.floor(+this.form.check_time[0].format('x') / 1000)
      : '';
    const check_end_time = this.form.check_time[1]
      ? Math.floor(+this.form.check_time[1].format('x') / 1000)
      : '';
    const query_params = {
      id: this.form.search_type === 0 ? this.form.search_value : '',
      order_id: this.form.search_type === 1 ? this.form.search_value : '',
      express_name: this.form.search_type === 2 ? this.form.search_value : '',
      express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
      express_address: this.form.search_type === 4 ? this.form.search_value : '',
      goods_title: this.form.goods_title,
      warehouse_id: this.form.warehouse_id === 0 ? '' : this.form.warehouse_id,
      create_start_time: start_time,
      create_end_time: end_time,
      check_time_start: check_start_time,
      check_time_end: check_end_time,
      status: this.form.refund_type,
      is_download: '0',
      current: pages,
      reason_type: this.form.reason_type === 0 ? '' : this.form.reason_type,
    };
    await this.getRefundList(query_params);
  }
  @changeLoading(['is_loading'])
  async sortList(pages: number = 1, sort: string) {
    const start_time = this.form.create_time[0]
      ? Math.floor(+this.form.create_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.create_time[1]
      ? Math.floor(+this.form.create_time[1].format('x') / 1000)
      : '';
    const check_start_time = this.form.check_time[0]
      ? Math.floor(+this.form.check_time[0].format('x') / 1000)
      : '';
    const check_end_time = this.form.check_time[1]
      ? Math.floor(+this.form.check_time[1].format('x') / 1000)
      : '';
    const query_params = {
      id: this.form.search_type === 0 ? this.form.search_value : '',
      order_id: this.form.search_type === 1 ? this.form.search_value : '',
      express_name: this.form.search_type === 2 ? this.form.search_value : '',
      express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
      express_address: this.form.search_type === 4 ? this.form.search_value : '',
      goods_title: this.form.goods_title,
      warehouse_id: this.form.warehouse_id === 0 ? '' : this.form.warehouse_id,
      create_start_time: start_time,
      create_end_time: end_time,
      check_time_start: check_start_time,
      check_time_end: check_end_time,
      status: this.form.refund_type,
      is_download: '0',
      current: pages,
      reason_type: this.form.reason_type === 0 ? '' : this.form.reason_type,
      sort_amount: sort,
    };
    await this.getRefundList(query_params);
  }
  @changeLoading(['is_loading'])
  async changeRefundType() {
    const start_time = this.form.create_time[0]
      ? Math.floor(+this.form.create_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.create_time[1]
      ? Math.floor(+this.form.create_time[1].format('x') / 1000)
      : '';
    const query_params = {
      id: this.form.search_type === 0 ? this.form.search_value : '',
      order_id: this.form.search_type === 1 ? this.form.search_value : '',
      express_name: this.form.search_type === 2 ? this.form.search_value : '',
      express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
      express_address: this.form.search_type === 4 ? this.form.search_value : '',
      goods_title: this.form.goods_title,
      warehouse_id: this.form.warehouse_id === 0 ? '' : this.form.warehouse_id,
      create_start_time: start_time,
      create_end_time: end_time,
      status: this.form.refund_type,
      is_download: '0',
      current: 1,
      reason_type: this.form.reason_type === 0 ? '' : this.form.reason_type,
    };
    await this.getRefundList(query_params);
  }
  // 获取发货门店
  @changeLoading(['is_loading'])
  async getWarehouseList() {
    const res: any = await getWarehouseList();
    this.warehouse_list = initWarehouseData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.warehouse_list = initWarehouseData(res.data);
    const obj = {
      id: 0,
      name: '全部',
    };
    this.warehouse_list.unshift(obj as any);
  }
  // 获取售后列表
  @changeLoading(['is_loading'])
  async getRefundList(query_params?: QueryParams) {
    const res: any = await getRefundList(query_params);
    this.refund_data = initRefundData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    const data = initRefundData(res.data);
    data.detail.forEach((item, index) => {
      this.rowsKeys.push(Number(item.id));
      data.detail[index] = {
        ...item,
        level: 0,
        children: [{ ...item, level: 1, id: '1-' + item.id }],
      };
    });
    this.refund_data = data;
  }

  // 导出
  async exportData() {
    // const start_time = this.form.create_time[0]
    //   ? Math.floor(+this.form.create_time[0].format('x') / 1000)
    //   : '';
    // const end_time = this.form.create_time[1]
    //   ? Math.floor(+this.form.create_time[1].format('x') / 1000)
    //   : '';
    // const query_params = {
    //   id: this.form.search_type === 0 ? this.form.search_value : '',
    //   order_id: this.form.search_type === 1 ? this.form.search_value : '',
    //   express_name: this.form.search_type === 2 ? this.form.search_value : '',
    //   express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
    //   express_address: this.form.search_type === 4 ? this.form.search_value : '',
    //   goods_title: this.form.goods_title,
    //   warehouse_id: this.form.warehouse_id === 0 ? '' : this.form.warehouse_id,
    //   create_start_time: start_time,
    //   create_end_time: end_time,
    //   status: this.form.refund_type,
    //   is_download: 1,
    //   current: this.refund_data.current,
    //   reason_type: this.form.reason_type === 0 ? '' : this.form.reason_type,
    // };
    try {
      const r: any = await exportData({ current: 1, is_download: 1 });
      if (r.status === 402) {
        this.$message.error(r.message);
      }
      console.log(r);
    } catch (error) {
      // window.location.href = `https:${error}`;
    }
  }
  @changeLoading(['is_loading'])
  async created() {

    await this.getRefundList();
    await this.getWarehouseList();
  }
}
