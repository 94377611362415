import { request as axios } from '@/util/request';
import { WareData, PaginationData } from '@/api/operation/customer';
import fileDownload from 'js-file-download';

const { VUE_APP_API: FCREST } = process.env;
const getUrl = require('baimahu/url-with-param');

const API = {
  invoice: '/boss/indent/invoice', // 开票申请单
  export: `${FCREST}/boss/indent/invoice/export`, // 导出
}


// 发票数据接口
export interface InvoiceData {
  amount: number;
  create_time: number;
  ein: string;
  email_address: string;
  id: number;
  order: OrderData;
  order_id: number;
  pass_time: number;
  title: string;
  type: number;
  user_id: number;
}
interface OrderData {
  cancel_time: number;
  closed_time: number;
  confirmed_time: number;
  create_time: number;
  delivery_time: number;
  express_address: string;
  express_cost: number;
  express_mobile: string;
  express_name: string;
  express_number: string;
  goods: WareData;
  goods_cost: number;
  id: number;
  is_sf: number;
  number: string;
  paid_time: number;
  pay_type: number;
  register: RegisterData;
  remark: string;
  status: number;
  status_name: string;
  update_time: number;
  user_id: number;
}
interface RegisterData {
  avatar_url: string;
  mobile: string;
  nickname: string;
}
// 查询数据接口
export interface QueryParams {
  order_id?: string;
  express_name?: string;
  express_mobile?: string;
  express_address?: string;
  goods_title?: string;
  create_start_time?: string | number;
  create_end_time?: string | number;
  is_download: number | string;
  status?: number;
  current: number;
}
// 初始化发票数据
export function initInvoicedata(remote?: PaginationData<InvoiceData>): PaginationData<InvoiceData> {
  remote = remote || {} as PaginationData<InvoiceData>;
  return {
    current: +remote.current || 0,
    detail: remote.detail || [],
    limit: remote.limit || 0,
    total: remote.total || 0,
    last: remote.last || 0,
  }
}
// 获取用户发票数据
export async function getInvoiceData(query_params: QueryParams = {
  is_download: '0',
  current: 1,
}) {
  const url = getUrl(API.invoice, query_params);
  return await axios.get(url);
}


// 开票操作
export async function addInvoice(id_list: number[]) {
  return await axios.post(API.invoice, {
    id_list,
  });
}


// 导出
export async function exportData(query_params?: any) {
  const url = getUrl(API.export, { ...query_params });
  return new  Promise((resolve,reject)=>{
    axios.get(`https:${url}`, { responseType: 'blob' }).then(res=>{
      fileDownload(res as any,'发票管理.xlsx');

      resolve(res)
    }).catch(()=>{
      reject(url)
    })
    }
  )
}