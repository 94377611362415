
const dateFormat = require('bmh/date-format');
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import {
  addInvoice,
  getInvoiceData,
  initInvoicedata,
  InvoiceData,
  QueryParams,
  exportData,
} from '@/api/order/invoice';
import { PaginationData } from '@/api/operation/customer';
import moment, { Moment } from 'moment';
import CustomModal from '@/component/custom-modal.vue';
const INVOICE_STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待开票',
  },
  {
    id: 2,
    value: '已开票',
  },
];
const SEARCH_TYPE = [
  {
    id: 0,
    value: '发票ID',
  },
  {
    id: 1,
    value: '订单ID',
  },
  {
    id: 2,
    value: '收货人姓名',
  },
  {
    id: 3,
    value: '收货人手机号',
  },
  {
    id: 4,
    value: '收货人地址',
  },
];
interface Form {
  search_type: number;
  search_value: string;
  ware_title: string;
  create_time: Moment[];
  invoice_status: number;
}
function getForm(): Form {
  return {
    search_type: 0,
    search_value: '',
    ware_title: '',
    create_time: [],
    invoice_status: 0,
  };
}
enum OrderStatus {
  '待支付' = 1,
  '待发货',
  '待收货',
  '已收货',
  '已取消',
  '已关闭',
}
@Component({
  name: 'OrderInvoice',
  components: {
    CustomModal,
  },
})
export default class OrderInvoice extends Vue {
  confirm_invoice_id_arr: number[] = [];
  form: Form = getForm(); // 搜索表单数据
  invoice_data: PaginationData<InvoiceData> = initInvoicedata(); // 发票数据
  is_loading: boolean = false;
  select_num: number = 0; //已选数量
  is_show_invoice_modal: boolean = false;
  selectedRowKeys: any = [];
  current: number = 0;
  get invoiceStatus() {
    return INVOICE_STATUS;
  }
  get searchType() {
    return SEARCH_TYPE;
  }
  get orderStatus() {
    return OrderStatus;
  }
  check_all: boolean = false; // 是否全选
  dateFormat(time: any) {
    return dateFormat(new Date(time * 1000), '%Y-%m-%d %H:%M:%S');
  }
  onSelectChange(selectedRowKeys: any) {
    console.log(selectedRowKeys);
    this.selectedRowKeys = [];
    if (selectedRowKeys.length == 0) {
      this.invoice_data.detail.forEach(x => {
        if (this.selectedRowKeys.indexOf(x.id) !== -1) {
          this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(x.id), 1);
        }
      });
    }
    this.selectedRowKeys = selectedRowKeys;
    // this.selectedRowKeys.push(...selectedRowKeys);
    this.selectedRowKeys = Array.from(new Set(this.selectedRowKeys));
    this.select_num = this.selectedRowKeys.length;
    if (this.selectedRowKeys.length === this.invoice_data.detail.length) {
      this.check_all = true;
    } else {
      this.check_all = false;
    }
  }
  clearSearchMsg() {
    this.form.search_value = '';
    this.form.ware_title = '';
    this.form.create_time = [];
    this.clickSearchBtn();
  }

  @changeLoading(['is_loading'])
  async handleChange(data: any) {
    const start_time = this.form.create_time[0]
      ? Math.floor(+this.form.create_time[0].startOf('day').format('x') / 1000)
      : '';
    const end_time = this.form.create_time[1]
      ? Math.floor(+this.form.create_time[1].endOf('day').format('x') / 1000)
      : '';
    const query_params = {
      id: this.form.search_type === 0 ? this.form.search_value : '',
      order_id: this.form.search_type === 1 ? this.form.search_value : '',
      express_name: this.form.search_type === 2 ? this.form.search_value : '',
      express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
      express_address: this.form.search_type === 4 ? this.form.search_value : '',
      goods_title: this.form.ware_title,
      create_start_time: start_time,
      create_end_time: end_time,
      is_download: '0',
      invoice_status: this.form.invoice_status,
      current: data.current,
    };
    this.current = data.current;
    await this.getInvoiceData(query_params);
  }

  // 批量开票
  @changeLoading(['is_loading'])
  async batchInvoice() {
    if (!this.selectedRowKeys.length) {
      this.$message.error('请勾选发票');
      return;
    }
    this.confirm_invoice_id_arr = this.invoice_data.detail
      .filter((item: any) => item.pass_time == 0 && this.selectedRowKeys.includes(item.id))
      .map((item: any) => item.id);
    if (!this.confirm_invoice_id_arr.length) {
      this.$message.error('请选择待开票的发票');
      return;
    }
    this.is_show_invoice_modal = true;
  }

  paginationOption(data: any) {
    return {
      current: data.current || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条,每页${data.limit}条`,
      total: data.total || 0,
    };
  }

  // 点击开票按钮
  clickInvoiceBtn(item: any) {
    this.is_show_invoice_modal = true;
    this.confirm_invoice_id_arr.push(item.id);
  }

  // 关闭确认开票模态窗
  closeInvoiceModal() {
    this.is_show_invoice_modal = false;
    this.confirm_invoice_id_arr = [];
  }
  // 确认已开票
  @changeLoading(['is_loading'])
  async addInvoice() {
    const res: any = await addInvoice(this.confirm_invoice_id_arr);
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    } else {
      this.$message.success('开票成功');
    }
    this.is_show_invoice_modal = false;
    const start_time = this.form.create_time[0]
      ? Math.floor(+this.form.create_time[0].format('x') / 1000)
      : '';
    const end_time = this.form.create_time[1]
      ? Math.floor(+this.form.create_time[1].format('x') / 1000)
      : '';
    const query_params = {
      id: this.form.search_type === 0 ? this.form.search_value : '',
      order_id: this.form.search_type === 1 ? this.form.search_value : '',
      express_name: this.form.search_type === 2 ? this.form.search_value : '',
      express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
      express_address: this.form.search_type === 4 ? this.form.search_value : '',
      goods_title: this.form.ware_title,
      create_start_time: start_time,
      create_end_time: end_time,
      is_download: '0',
      invoice_status: this.form.invoice_status,
      current: this.current,
    };
    await this.getInvoiceData(query_params);
    this.confirm_invoice_id_arr = [];
    this.selectedRowKeys = [];
    this.select_num = 0;
    // this.$message.success('');
  }
  // 全部选中
  checkAll() {
    if (this.check_all) {
      this.invoice_data.detail.forEach((item, index) => {
        this.selectedRowKeys.push(index);
      });
    } else {
      this.selectedRowKeys = [];
    }
  }

  async exportData() {
    // const start_time = this.form.create_time[0]
    //   ? Math.floor(+this.form.create_time[0].format('x') / 1000)
    //   : '';
    // const end_time = this.form.create_time[1]
    //   ? Math.floor(+this.form.create_time[1].format('x') / 1000)
    //   : '';
    // const query_params = {
    //   id:this.form.search_type===0 ? this.form.search_value:'',
    //   order_id: this.form.search_type === 1 ? this.form.search_value : '',
    //   express_name: this.form.search_type === 2 ? this.form.search_value : '',
    //   express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
    //   express_address: this.form.search_type === 4 ? this.form.search_value : '',
    //   goods_title: this.form.ware_title,
    //   create_start_time: start_time,
    //   create_end_time: end_time,
    //   is_download: 1,
    //   status: this.form.invoice_status,
    //   current: this.invoice_data.current,
    // };
    try {
      const r: any = await exportData({ current: 1, is_download: 1 });
      if (r.status === 402) {
        this.$message.error(r.message);
      }
      console.log(r);
    } catch (error) {
      // window.location.href = `https:${error}`;
    }
  }

  @changeLoading(['is_loading'])
  async clickSearchBtn() {
    const start_time = this.form.create_time[0]
      ? Math.floor(+this.form.create_time[0].startOf('day').format('x') / 1000)
      : '';
    const end_time = this.form.create_time[1]
      ? Math.floor(+this.form.create_time[1].endOf('day').format('x') / 1000)
      : '';
    const query_params = {
      id: this.form.search_type === 0 ? this.form.search_value : '',
      order_id: this.form.search_type === 1 ? this.form.search_value : '',
      express_name: this.form.search_type === 2 ? this.form.search_value : '',
      express_mobile: this.form.search_type === 3 ? this.form.search_value : '',
      express_address: this.form.search_type === 4 ? this.form.search_value : '',
      goods_title: this.form.ware_title,
      create_start_time: start_time,
      create_end_time: end_time,
      is_download: '0',
      invoice_status: this.form.invoice_status,
      current: 1,
    };
    console.log('1111');
    await this.getInvoiceData(query_params);
  }
  // 获取发票列表
  @changeLoading(['is_loading'])
  async getInvoiceData(query_params?: QueryParams) {
    const res: any = await getInvoiceData(query_params);
    this.invoice_data = initInvoicedata();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.invoice_data = initInvoicedata(res.data);
  }

  @changeLoading(['is_loading'])
  async created() {
    await this.getInvoiceData();
  }
  changeTime(val) {
    return moment(val).format('YYYY-MM-DD HH:mm:ss');
  }
}
